.attendance{
  transition: 'box-shadow .1s' !important;
}

.attendance.attendance-others{
  min-width: 'unset' !important;
  width: '6em' !important;
}

.attendance-others-input{
  flex: 1;
  transition: 'box-shadow .1s';
  margin-left: '1rem';
}