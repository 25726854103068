.ui.table.selectable{cursor: pointer}
.ui.table tr.red{background-color: hsl(0,100%,95%);}
.ui.table.selectable tr.red:hover{background-color: hsl(0,95%,90%) !important;}
.ui.table tr.yellow{background-color: hsl(60,100%,95%);}
.ui.table.selectable tr.yellow:hover{background-color: hsl(60,95%,90%) !important;}
.ui.table tr.green{background-color: hsl(120,100%,95%);}
.ui.table.selectable tr.green:hover{background-color: hsl(120,95%,90%) !important;}
.ui.table tr.blue{background-color: hsl(240,100%,95%);}
.ui.table.selectable tr.blue:hover{background-color: hsl(240,95%,90%) !important;}

.ui.table tr.inverted, .ui.selectable.table tbody tr.inverted:hover{color: white !important;}

.ui.table tr.red.inverted{background-color: hsl(0,71%,51%);}
.ui.table.selectable tr.red.inverted:hover{background-color: hsl(0,71%,46%) !important;}
.ui.table tr.yellow.inverted{background-color: hsl(60,71%,51%);}
.ui.table.selectable tr.yellow.inverted:hover{background-color: hsl(60,71%,46%) !important;}
.ui.table tr.green.inverted{background-color: hsl(120,71%,51%);}
.ui.table.selectable tr.green.inverted:hover{background-color: hsl(120,71%,46%) !important;}
.ui.table tr.blue.inverted{background-color: hsl(240,71%,51%);}
.ui.table.selectable tr.blue.inverted:hover{background-color: hsl(240,71%,46%) !important;}