.recordTable {
    width: 100%;
    overflow-x: auto;
}

.hoverable:hover {
    box-shadow: 0 2px 8px #bbb !important;
}

.ui.segment {
    margin: 0.5rem 0 0 0 !important;
    vertical-align: middle;
}

.ui.list .list, ol.ui.list ol, ul.ui.list ul {
    padding: 0.5rem 0 0.5rem 3.5rem !important;
}

.level1 {
    background-color: #767676 !important;
    /* border-color: #767676 !important; */
    color: white !important;
}

.level2 {
    background-color: #b3b3b3 !important;
    /* border-color: #b3b3b3 !important; */
    color: white !important;
}

.paragraph {
    white-space: pre-wrap;
}

@media only screen and (max-width: 768px) {
    .mobile-align-left {
        text-align: left !important;
    }
}