.ui.modal.visible .disabled.field .ui.input,
.ui.modal.visible .disabled.field .ui.selection{
  opacity: 1;
}

.ui.form .fields {margin-bottom: 0;}
.collapsed-input>input{width: 100% !important;}
.ui.form .fields+.ui.label {margin-top: 1em !important;}

.ui.form table .fields{
  margin-bottom: 0;
}