/* default css */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto;
}
button{font-family: inherit !important;}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* button margin */
.ui.button {
    margin: 0 .4em !important;
}

/********************************************** helper css **********************************************//*** TYPOGRAPHY  ***/
.ui.header, .ui.menu, .ui.button, button.google-login, body,h1,h2,h3,h4,h5,textarea,input,select, .header, .ui.steps .step .title{
  font-family: 'Noto Sans CJK TC', Noto, Helvetica, Arial, 'Microsoft JHengHei', sans-serif !important;
}

#root {
  background-color: #fcfcfc;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

:root {
  --main-top-padding: 2rem;
}

/* root container height */
.full-height {
    min-height: 100vh;
    margin-top: 0 !important;
}

/* appraisal form */
.appraisal-form {
    height: 93.2vh;
    overflow-y: scroll;
}

/* appraisal canvas */
.appraisal-canvas {
    height: 93.2vh;
    overflow: hidden;
}

#top-mobile-nav>.item{
  padding: 0.571428em 0.571428em;
}

#main{
  flex: 1;
  overflow-y: hidden;
}

#main>.row{
  height: 100%;
  display: flex !important;
  overflow-y: auto;
}

/* grid no padding (especially for side bar) */
#side-bar {
  background-color: #ffffff;
  border-color: transparent !important; 
  padding: 0 !important;
  overflow-y: auto;
}

#side-bar>.menu {
  margin: 0 !important;
  flex: 1;
}

#side-bar, #side-bar>.menu{
  width: 16rem !important;
}

/* nav menu font bold (for top-nav and side-bar) */
#top-nav .item {
  font-weight: bolder !important;
}

#content {
  height: 100%;
  width: unset !important;
  overflow-y: auto;
  flex: 1;
}

.ui.grid {
  margin: 0 !important;
}

#side-bar .item{
  padding: 0.42857143em 0.42857143em;
  margin: 0;
}

.ui.secondary.vertical.pointing.menu .item{
  border-bottom: none;
  border-radius: 0 !important;
  margin: 0 -2px 0 0;
  border-right: 2px solid transparent;
}

#side-bar .content .item {
  margin-left: 0.75rem;
}
#side-bar .item.sub-sub-item {
  margin-left: 3rem;
}

#side-bar .item{
  position: relative;
}

.menu-index{
  margin-right: 0.5rem;
}

/* cursor */
.cursor {
    cursor: pointer;
}

/* error message */
.errorMsg {
    margin-top: 8px;
    color: red;
}

/* text align center */
.text-center {
    text-align: center;
}

/* text align left */
.text-left {
  text-align: left !important;
}

/* no padding top */
.no-padding-top {
  padding-top: 0 !important;
}

/* top margin */
.top-margin {
  margin-top: 1.5em !important;
}

/* no margin top */
.ui.segment.no-margin-top,
.no-margin-top {
  margin-top: 0 !important;
}

/* from group margin */
.form-group-margin, .ui.segments>.ui.segment>.fields.form-group-margin {
    margin-bottom: 2em !important;
}

.form-group-margin-narrow{
  margin-bottom: 0.5rem !important;
}

/* form group no margin */
.form-group-no-margin {
    margin-bottom: 0 !important;
}

/* margin for fields in form group */
.fields.grouped>.field {
    margin-bottom: 1em !important;
}

.fields.grouped.no-margin>.field {
    margin-bottom: 0 !important;
}

/* form group padding for multiple layer */
.fields.next-layer,
.field.next-layer, div.next-layer {
    padding-left: 25px !important;
}

/* form group align items flex end (espically for inline button */
.fields.form-group-flex-end {
    align-items: flex-end !important;
}

/* form error label */
.field:not(.date-input)+.ui.pointing.label {
  margin-top: 0 !important;
}

/* form padding */
.form-padding {
  padding: 2em !important;
  padding-bottom: 0.5em !important;
}

.segment-padding {
  padding: 1.5em !important;
  padding-top: 1em !important;
}

/* bold */
.bold {
    font-weight: bolder;
}

/* larger font */
.larger-font {
    font-size: 1.3rem !important;
}

/* button margin with input field error */
.ui.button.buttonErrorMargin, .buttonMarginTop {
    margin-top: 1.5em !important;
}

/* button margin with input field error */
.ui.button.align-bottom-button-form {
    margin-bottom: 1.3em !important;
}

.ui.button.align-bottom-button {
    margin-bottom: 0.5rem !important;
}

/* segment overflow */
.segmentOverflow {
    overflow-x: auto;
    border-right: solid 3em rgba(0, 0, 0, 0) !important;
    border-left: solid 3em rgba(0, 0, 0, 0) !important;
}

/* Incomplete Fields */
.fields.incomplete {
    border: 1px red solid;
    padding: 0.5em;
}

/* grid background */
.gridBg {
    background-color: transparent;
    background-image: linear-gradient(0deg, transparent 24%, rgba(0, 0, 0, .05) 25%, rgba(0, 0, 0, .05) 26%, transparent 27%, transparent 74%, rgba(0, 0, 0, .05) 75%, rgba(0, 0, 0, .05) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgba(0, 0, 0, .05) 25%, rgba(0, 0, 0, .05) 26%, transparent 27%, transparent 74%, rgba(0, 0, 0, .05) 75%, rgba(0, 0, 0, .05) 76%, transparent 77%, transparent);
    background-size: 50px 50px;
}

/* tab style */
.ui.segment.tab {
    margin-top: 0 !important;
}

/* increase sen student record disabled field readability */
#sen-student-record .disabled.field, 
#sen-student-record .field:not(.disabled)>.disabled.input,
#sen-student-record .dropdown.icon{
    opacity: 1
}

.left-school{
    color: #888;
    background-color: #eee;
}
.ui.selectable.table tbody tr.left-school:hover,
.ui.table tbody tr td.selectable.left-school:hover{
    color: #666 !important;
    background-color: #eee !important;
}
/* responsiveness in sen/record */
.form .disabled.field .ui.disabled.input input,
.form .disabled.fields .field .ui.disabled.input input {
    width: 100%;
}
/* allow scroll for many, many search results */
.results.transition.visible {
    max-height: 20.5rem;
    overflow-y: scroll;
}

.modal .content .ui.basic.segment {
    padding: 0;
}

.no-vertical-padding{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* fix fullscreen modal alignment*/
.ui.fullscreen.modal.active.visible{
    left: unset !important;
}

.no-side-padding{
  padding-left: 0 !important;
  padding-right: 0 !important;
}


.ui.table col.one.wide {width: 6.25% !important;}
.ui.table col.two.wide{width: 12.5% !important;}
.ui.table col.three.wide{width: 18.75% !important;}
.ui.table col.four.wide{width: 25% !important;}
.ui.table col.five.wide{width: 31.25% !important;}
.ui.table col.six.wide{width: 37.5% !important;}
.ui.table col.seven.wide{width: 43.75% !important;}
.ui.table col.eight.wide{width: 50% !important;}
.ui.table col.nine.wide{width: 56.25% !important;}
.ui.table col.ten.wide{width: 62.5% !important;}
.ui.table col.eleven.wide{width: 68.75% !important;}
.ui.table col.twelve.wide{width: 75% !important;}
.ui.table col.thirteen.wide{width: 81.25% !important;}
.ui.table col.fourteen.wide{width: 87.5% !important;}
.ui.table col.fifteen.wide{width: 93.75% !important;}
.ui.table col.sixteen.wide{width: 100% !important;}

.ui.form .field.error .checkbox:not(.toggle):not(.slider) label:before{
  background: hsl(0, 95%, 95%) !important;
  border-color: hsl(0, 69%, 70%) !important;
}

/** HANDLE SIDEBAR OVERFLOW ISSUE **/
.main>.row, #side-bar{height:100%;}

#side-bar .secondary.vertical.menu{
  overflow-y: auto;
}

/** HANDLE table cell ellipsis problem **/
.ui.fixed.table td.textlessCell, .ui.fixed.table th.textlessCell{
  text-overflow: clip;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ui.fixed.table td,.ui.fixed.table th{
  word-break: break-word;
}

.list-less-padding{
  padding-inline-start: 1em;
}

.break-word{
  overflow-wrap: break-word;
}

.ui[class*="very compact"].table thead th{
  padding: 0.4em;
}

.ui.segment .ui.form .fields:not(.form-group-margin) {
  margin-bottom: 0;
}

.ui[class*="right labeled"].icon.button.compact {
  padding-right: 3.071429em!important;
  padding-left: .58928571em!important;
  width: calc(100% - .58928571em * 2);
}

.header.item.center.aligned{
  text-align: center;
  text-shadow: 0 0 0.5px rgba(0,0,0,0.7);
}

#editPermission .inline.fields{
  flex-wrap: wrap;
}
#editPermission .permission-group, .margin-left-1rem{
  margin-left: 1rem !important;
}
#editPermission .inline.fields .field{
  margin: 0.5rem;
}

.input-with-button{
  display: flex;
  align-items: flex-end;
  margin-left: 0px !important;
}

:not(.draggable-questions)>.input-with-button .button {
    margin-bottom: 15px !important;
}

.input-with-button>.wide.field, .flex-1 {
  flex: 1 !important;
}

table p.addEvaluationButton{
  text-align: center;
  margin-top: 0.25rem;
}

.draggable-questions:before {
  content: "\f142";
  font-family: "Icons";
  position: absolute;
  top: 50%;
  left: 0;
  opacity: 0.55;
  transform: translate(0.5rem, -50%) scale(1.2);
}

.piechart{
  max-width: 160px;
  margin: 0 auto;
}

div.piechart>svg{
  overflow: visible;
  font-size: 10px;
}

.survey-legend{
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  outline: 1px black solid;
  display: inline-block;
  vertical-align: middle;
}

.legend-label:nth-last-child(n+1){
  margin-right: 1rem;
}

.more-opaque.ui.disabled.input, .disabled .ui.disabled.selection, .more-opaque-date {
  opacity: 1 !important;
}

.aggregateResult{
  text-shadow:
   1px  0px 1px rgba(0,0,0,.6),
   0px  1px 1px rgba(0,0,0,.6),
  -1px  0px 1px rgba(0,0,0,.6),
   0px -1px 1px rgba(0,0,0,.6);
  letter-spacing: 0.125em;
}

.textarea-text {
  white-space: pre-wrap;
}

.ui.button-margin-bottom {
  margin-bottom: 10px !important;
}

.ui.labeled.input>.label:not(.corner) {
  padding: .78571429em .5833em;
}

.multiselect-style-label>.label{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  font-size: 1em;
  padding: .35714286em .78571429em;
  margin: .14285714rem .28571429rem .14285714rem 0;
  box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset;
}

.multiselect-style-label{
  margin-top: .28571429rem !important;
}

.table-cell-with-dropdown{
  overflow: visible !important;
}

.flex-fields{
  display: flex;
  justify-content: center;
  align-items: center;
}

.noPointerEvents{
  pointer-events: none !important;
}

.ui.icon.header .corner.icon {
  font-size: 1.35em;
}

.ui.header .icon.iconStyle {
    color: rgba(0,0,0,.87);
    display: table-cell;
    opacity: 1;
    font-size: 1.5em;
    padding-top: 0;
    vertical-align: middle;
}

.red{color: red}

.fridayActivityDateInput{width: 10rem;}
.fridayActivityCode{width: 5rem;}
.fridayActivityName{width: 12rem;}

.table-cell-with-dropdown .ui.input input[type=date] {
  padding: 0.28571429rem;
}

.ui.form input[type="date" i]::-webkit-calendar-picker-indicator {
  margin-inline-start: 12px;
  position: absolute;
  right: 0.28571429rem;
}

.ui.compact.table.friday-lessons th {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.hidden-row{
  display: none !important;
}

.overflowX {
  overflow-x: auto !important;
}

.teacherLabel {
  margin-bottom: 0.2em !important;
  white-space: nowrap !important;
}

.align-bottom-button{
  align-self: flex-end;
}

.results.transition.visible{
  width: 100% !important;
}

.button-margin-bottom {
  margin-bottom:1.5em !important;
}

.underline{
  text-decoration: solid underline;
}

.activity-date-label{
  padding-left: 10;
  padding-right: 10;
}

.line-through{
  text-decoration: line-through;
}

.ui.checkbox.narrow{
  margin: 0 0.5em;
}

.ui.header span.sub.header{
  display: initial;
}

.merged-cell-padding{
  padding: 1rem !important;
}

.vertical-center-field{
  display: flex;
  align-items: center;
}

.vertical-center-field>.wide.field, .flex-1 {
  flex: 1 !important;
}

.talent_search_field:nth-child(n+2){
  margin-top: 0.4em !important;
  margin-bottom: 0.4em !important;
  flex-wrap: wrap;
}

.talent_accordion{
  margin-top: 0.4em !important;
  margin-bottom: 0.4em !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.number-field{
  width: 5rem !important;
}


/*** Allow direct printing of content ***/
@media print{
  #side-bar, #top-nav { display: none !important;}
  #root{position: static !important;display:block;}
  #side-bar { display: none !important;}
}

#top-nav{
  position: relative;
  z-index: 1;
}
#top-nav .ui.menu.fixed {
  position: static;
}
#top-mobile-nav{
  margin-top: 0;
  position: absolute;
  right: 0;
  left: 0;
  width: auto;
  height: calc(100vh - 7rem);
  overflow-y: auto;
  z-index: -1;
  transform: translateY(-100%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
#top-mobile-nav.open{
  transform: translateY(0);
  transition: 0.5s transform;
}

#nav-logo{
  height: 3rem; width: auto;
}
#nav-title{
  margin-left: 10px;
}

@media only screen and (max-width: 768px) {
  .ui.celled.grid>.column:not(.row), .ui.celled.grid>.row>.column{
    padding: 0 !important;
  }
}

@-moz-document url-prefix() {
  h2.ui.center.header>.content {
    vertical-align: -webkit-baseline-middle;
  }
}

@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
  h2.ui.center.header>.content {
    vertical-align: sub;
  }
}

.segment.no-margin-top+table.bottom.attached{
  border-bottom: 0;
}

.segments>.ui.segment.no-margin-top{
  padding: 0.5em;
}